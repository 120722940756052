<template>
  <div>
    <div class="row align--center p-75">
      <div class="flex xs12 md6">
        <va-input
          color="info"
          v-model="form.type_name.value"
          :label="$t('reports.inputs.type.name')"
          :error="!!form.type_name.errors.length"
          :error-messages="translatedErrors(form.type_name.errors)"
          @blur="validate('type_name')"
        />
      </div>
      <div class="flex xs12 md6">
        <va-select
          v-model="selectedLeader"
          :label="$t('reports.inputs.type.leader')"
          :options="leadersList"
          :loading="isLoading.leaders"
          :no-options-text="$t('layout.empty')"
          :disabled="isLoading.leaders"
          @update-search="asyncFindLeader"
          text-by="name"
          searchable
        />
      </div>
    </div>
    <div class="row align--center p-75">
      <div class="flex xs12 md4">
        <va-input
          color="info"
          type="number"
          step="1"
          min="0"
          v-model="form.type_asistance.value"
          :label="$t('reports.inputs.type.assistance')"
          :error="!!form.type_asistance.errors.length"
          :error-messages="translatedErrors(form.type_asistance.errors)"
          @blur="validate('type_asistance')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.preaching_code.value"
          :label="$t('reports.inputs.type.code')"
          :error="!!form.preaching_code.errors.length"
          :error-messages="translatedErrors(form.preaching_code.errors)"
          @blur="validate('preaching_code')"
          disabled
        />
      </div>
      <div class="flex xs12 md4">
        <va-date-picker
          id="preaching-date"
          :label="$t('reports.inputs.type.date')"
          :config="dateConfig"
          v-model="preachingDate"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          @click="updateData"
        >
          <text-loading
            :loading="false"
            icon="fa fa-plus"
          >
            {{ $t('layout.form.add') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMixin } from '@/services/validator'

export default {
  name: 'PreachingModal',
  props: {
    preachingCode: {
      type: String,
      default: '',
    },
    preachingLeader: {
      type: Object,
      default: () => null,
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      isLoading: {
        leaders: false,
      },
      isError: {
        leaders: false,
      },
      leadersList: [],
      selectedLeader: '',
      form: {
        preaching_code: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        type_name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        type_asistance: {
          value: '',
          validate: {
            required: true,
            minValue: 1,
          },
          errors: [],
        },
      },
      dateConfig: {},
      preachingDate: null,
    }
  },
  watch: {
    preachingCode (val) {
      this.setFormValue('preaching_code', val)
    },
    preachingLeader (val) {
      if (!val) return

      this.selectedLeader = val
    },
  },
  created () {
    if (this.preachingCode) {
      this.setFormValue('preaching_code', this.preachingCode)
    }
    if (this.preachingLeader) {
      this.selectedLeader = this.preachingLeader
    }
    this.asyncFindLeader('')
  },
  methods: {
    async asyncFindLeader (query) {
      await this.asyncUsers(query, 'leaders', 'users')
      if (this.isError.leaders) {
        this.asyncFindLeader(query)
      }
    },
    async asyncUsers (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }
      if (!query.includes('&limit=')) {
        query += '&limit=25'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    updateData () {
      this.validateAll()
      if (!this.formReady) return

      const preaching = this.getFormData(this.preaching)
      if (this.selectedLeader) {
        preaching.leader_id = this.selectedLeader.id
        preaching.leader = this.selectedLeader
      }
      if (this.preachingDate) {
        preaching.preaching_date = this.preachingDate
      }
      this.$emit('submit', preaching)
      this.clearForm()
    },
    clearForm () {
      this.setFormValue('type_name', '')
      this.setFormValue('type_asistance', '')
      this.preachingDate = null
      this.selectedLeader = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.p-75 {
  .flex {
    padding: $layout-padding;
  }
}
</style>
